<template>
	<w-layout fill-height row>
		<w-flex offset-xs1 xs4 mr-2>
			<w-layout align-center column fill-height justify-center>
				<w-flex v-t="'workflows.none.title'" display-1 mb-4 shrink />
				<w-flex v-t="'workflows.none.text'" mb-2 shrink subheading />
				<w-btn v-if="canAddWorkflows" @click.stop="addWorkflows()">{{ $t('workflows.actions.add') }}</w-btn>
			</w-layout>
		</w-flex>
		<w-flex xs6 ml-2>
			<w-layout align-center fill-height>
				<ScrumBoardImage height="80%" />
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WorkflowsModuleGuard from '@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard'

export default {
	name: 'NoExistingWorkflows',
	components: {
		ScrumBoardImage: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Workflows/ScrumBoardImage')
		})
	},
	mixins: [WorkflowsModuleGuard],
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
		}),
		...mapGetters({
			userHasWriteAccess: 'workflows/userHasWriteAccess'
		}),
		canAddWorkflows() {
			return this.userHasWriteAccess
		},
		serviceRoute() {
			return this.isAccountant ? 'customer-workflows-drawer' : 'company-settings-workflows-drawer'
		}
	},
	methods: {
		addWorkflows: function () {
			if (!this.canAddWorkflows) {
				return
			}
			this.appService.goTo({
					name: this.serviceRoute,
					params: {
						workflowId: 'create'
					}
				},
				true
			)
		}
	}
}
</script>
