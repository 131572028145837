<template>
	<v-layout column fill-height>
		<w-resource
			ref="resource"
			:searchable="true"
			paginable="server"
			:actions="resourceActions"
			trans-key="workflows"
			@item-create="launchWorkflowCreation"
			@item-edit="openDetails"
			@item-view="openDetails"
		>
			<template v-slot:no-data>
				<NoExistingWorkflows />
			</template>
		</w-resource>
		<w-navigation-drawer :value="showDrawer" absolute clipped default-width="33%" min-width="550px" resizable right>
			<template v-slot:default="{ on, width }">
				<w-layout column fill-height>
					<router-view :width="width" v-on="on" />
				</w-layout>
			</template>
		</w-navigation-drawer>
	</v-layout>
</template>

<script>
import WorkflowManagerModuleGuard from '@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard';
import NoExistingWorkflows from '@/components/CustomersManager/CustomerDetails/Workflows/NoExistingWorkflows';
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'CustomerDetailsWorkflow',
	components: {
		NoExistingWorkflows,
	},
	mixins: [WorkflowManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object,
		},
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
		}),
		...mapGetters({
			userHasWriteAccess: 'workflows/userHasWriteAccess'
		}),
		resourceActions(){
			return {
				create: this.userHasWriteAccess,
				delete: this.userHasWriteAccess ? this.deleteWorkflow : false,
				edit: this.userHasWriteAccess,
				view: !this.userHasWriteAccess,
				list: this.loadWorkflows,
			}
		},
		showDrawer: function () {
			return !['company-settings-workflows', 'customer-workflows'].includes(this.$route.name)
		}
	},
	watch: {
		vendorId(value, oldValue) {
			if (value !== oldValue) {
				this.$refs.resource.reset();
				this.$refs.resource.loadItems();
			}
		},
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.WORKFLOW_CREATED, action: (item) => this.$refs.resource.addItem(item) },
				{ event: this.events.WORKFLOW_MODIFIED, action: (item) => this.$refs.resource.replaceItem(item) }
			]
		},
		async deleteWorkflow(workflow) {
			await this.service.deleteWorkflow(this.vendorId, workflow.id);
		},
		async loadWorkflows(filters) {
			return this.service.listWorkflows(this.vendorId, filters,true);
		},
		openDetails(workflow = null) {
			const routeParams = {
				workflowId: workflow ? workflow.id.toString(16) : 'create',
			};
			let routeName = this.isAccountant ? 'customer-workflows-drawer' : 'company-settings-workflows-drawer';

			this.appService.goTo(
				{
					name: routeName,
					params: routeParams,
				},
				true,
			);
		},
		launchWorkflowCreation() {
			this.openDetails(null);
		},
	},
};
</script>
